import {
  changeDatepickerDate,
  formatYmdHisDot,
  regComma,
  regPhoneNumber,
} from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";
import { XTDataGrid } from "@/components/mui/xt-datagrid";
import XTDatePicker from "@/components/mui/xt-datepicker";
import { XTInput } from "@/components/mui/xt-input";
import { XTMenuItem, XTSelect } from "@/components/mui/xt-select";
import {
  Box,
  Checkbox,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Pagination,
  Radio,
} from "@mui/material";
import {
  GridColDef,
  GridValueGetterParams,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useOrderList, useOrderType } from "./_hook/use-order";
import { XTRadio, XTRadioGroup } from "@/components/mui/xt-radio";
import { XTCheckBox } from "@/components/mui/xt-checkbox";
import ExcelDialog from "./_component/excel-dialog";
import { useEffect, useState } from "react";
import CancelDialog from "./_component/cancel-dialog";
import UploadDialog from "./_component/upload-dialog";
import { useRecoilState } from "recoil";
import { alertDialogState } from "@/state";
import TemplateDialog from "./_component/template-dialog";

export default function Order() {
  // const { orderTypes } = useOrderType();

  const {
    listData,
    isFetching,
    refetch,
    schForm,
    schViewForm,
    handleChangeInput,
    handleChangePage,
    handleChangePageSz,
    handleClickDateButton,
    handleResetInput,
    handleChangeDatePicker,
    handleSearch,
    navigate,
    regist,
    cancel,
    excel,
    number,
    template,
    upload,
    order,
    setOrder,
    ivcNo,
    setIvcNo,
  } = useOrderList();

  const apiRef = useGridApiRef();
  const [uploadDialog, setUploadDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [excelDialog, setExcelDialog] = useState(false);
  const [templateDialog, setTemplateDialog] = useState(false);
  const [alertDialog, setAlertDialog] = useRecoilState(alertDialogState);

  const columns: GridColDef[] = [
    {
      field: "index",
      headerName: "번호",
      width: 50,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return listData!.applyList?.number - params.row.index;
      },
    },
    {
      field: "seqCd",
      headerName: "신청번호",
      headerAlign: "center",
      width: 120,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "aplyrTp",
      headerName: "신청구분",
      headerAlign: "center",
      width: 150,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return listData!.ApplyType[params.value];
      },
    },
    {
      field: "stat",
      headerName: "처리상태",
      headerAlign: "center",
      width: 80,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return listData!.ApplyStatus[params.value];
      },
    },
    {
      field: "nm",
      headerName: "수령인",
      headerAlign: "center",
      width: 170,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.applyPrivacy ? params.row.applyPrivacy.nm : "-";
      },
    },
    {
      field: "cph",
      headerName: "휴대폰번호",
      headerAlign: "center",
      width: 120,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.applyPrivacy
          ? regPhoneNumber(params.row.applyPrivacy.cph)
          : "-";
      },
    },
    {
      field: "chldrNm",
      headerName: "자녀이름",
      headerAlign: "center",
      width: 120,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.applyPrivacy && params.row.applyPrivacy.chldrNm
          ? params.row.applyPrivacy.chldrNm
          : "-";
      },
    },
    {
      field: "addrDtl",
      headerName: "배송지",
      headerAlign: "center",
      width: 300,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.applyPrivacy && params.row.applyPrivacy.addr
          ? `${params.row.applyPrivacy.addr} ${
              params.row.applyPrivacy.addrDtl
                ? params.row.applyPrivacy.addrDtl
                : ""
            } `
          : "-";
      },
    },
    {
      field: "goods",
      headerName: "신청물품",
      headerAlign: "center",
      width: 350,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.applyGoodsList
          .map(
            (item: {
              seq: number;
              qty: string;
              goods: {
                seq: number;
                nm: string;
              };
            }) => item.goods.nm + " " + item.qty
          )
          .join(",")}`,
    },
    {
      field: "ivcNo",
      headerName: "운송장번호",
      headerAlign: "center",
      width: 300,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params.row.stat != 3) {
          const ivc =
            ivcNo[ivcNo.findIndex((item) => item.seq === params.row.seq)];
          return (
            <>
              {params.row.stat == 1 && params.row.divYn !== "Y" ? (
                <>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    {ivc?.flag ? (
                      <XTInput
                        placeholder="운송장번호를 입력해 주세요."
                        value={ivc?.ivcNo}
                        onChange={(e) => {
                          const updatedIvcNo = [...ivcNo];
                          const index = updatedIvcNo.findIndex(
                            (item) => item.seq === params.row.seq
                          );
                          if (/^\d*$/.test(e.target.value)) {
                            updatedIvcNo[index].ivcNo = e.target.value;
                          } else {
                            if (!/^\d*$/.test(ivc?.ivcNo)) {
                              updatedIvcNo[index].ivcNo = "";
                            }
                          }
                          setIvcNo(updatedIvcNo);
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                        sx={{
                          minWidth: 210,
                        }}
                      />
                    ) : (
                      <p style={{ margin: "auto" }}>{ivc?.ivcNo}</p>
                    )}

                    <XTButton
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={(event) => {
                        const updatedIvcNo = [...ivcNo];
                        event.stopPropagation();
                        if (ivc?.flag) {
                          if (ivc?.ivcNo) {
                            handleRegistNumber(ivc);
                          } else {
                            setAlertDialog({
                              open: true,
                              text: "운송장번호를 입력해주세요.",
                              type: "complete",
                              title: "알림",
                              handleOK: () => {
                                setAlertDialog({
                                  ...alertDialog,
                                  open: false,
                                });
                                setCancelDialog(false);
                              },
                              handleClose: null,
                            });
                          }
                        } else {
                          updatedIvcNo[
                            ivcNo.findIndex(
                              (item) => item.seq === params.row.seq
                            )
                          ].flag = true;
                          setIvcNo(updatedIvcNo);
                        }
                      }}
                    >
                      {ivc?.flag ? "등록" : "수정"}
                    </XTButton>
                  </Grid>
                </>
              ) : (
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <p style={{ margin: "auto" }}>
                    {ivc?.ivcNo ? ivc?.ivcNo : "-"}
                  </p>
                  {ivc?.ivcNo && (
                    <XTButton
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();
                        window.open(
                          `https://www.cjlogistics.com/ko/tool/parcel/tracking?gnbInvcNo=${ivc?.ivcNo}`,
                          "_blank"
                        );
                      }}
                    >
                      조회
                    </XTButton>
                  )}
                </Grid>
              )}
            </>
          );
        } else {
          return <>-</>;
        }
      },
    },
    {
      field: "aplyYmd",
      headerName: "신청일시",
      headerAlign: "center",
      width: 160,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return formatYmdHisDot(params.value, params.row.aplyHis);
      },
    },
    {
      field: "tagdsYmd",
      headerName: "출고일시",
      headerAlign: "center",
      width: 160,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return formatYmdHisDot(params.value, params.row.tagdsHis);
      },
    },
    {
      field: "cancYmd",
      headerName: "취소일시",
      headerAlign: "center",
      width: 160,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return formatYmdHisDot(params.value, params.row.cancHis);
      },
    },
  ];

  const handleRegistNumber = async (value: { seq: string; ivcNo: string }) => {
    try {
      const result = await number.mutateAsync(value);
      setAlertDialog({
        open: true,
        text: "저장되었습니다.",
        type: "complete",
        title: "알림",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          refetch();
        },
        handleClose: null,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleRegistOrder = async () => {
    const parmas = {
      seqArray: order!,
    };
    try {
      const result = await regist.mutateAsync(parmas);
      setAlertDialog({
        ...alertDialog,
        open: true,
        text: "출고처리 완료했습니다.",
        type: "complete",
        title: "알림",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          setOrder([]);
          refetch();
        },
      });
    } catch (err) {
      if (err && err.response && err.response.data.code === "CM008") {
        setAlertDialog({
          open: true,
          text: "출고처리 실패했습니다. <br />재고를 확인 후 다시 시도해주세요.",
          type: "complete",
          title: "알림",
          handleOK: () => {
            setAlertDialog({
              ...alertDialog,
              open: false,
            });
          },
          handleClose: null,
        });

        return;
      } else if (err && err.response && err.response.data.code === "CM003") {
        setAlertDialog({
          open: true,
          text: "출고처리 실패했습니다.",
          type: "complete",
          title: "알림",
          handleOK: () => {
            setAlertDialog({
              ...alertDialog,
              open: false,
            });
          },
          handleClose: null,
        });

        return;
      } else {
        setAlertDialog({
          open: true,
          text: "출고처리 실패했습니다.",
          type: "complete",
          title: "알림",
          handleOK: () => {
            setAlertDialog({
              ...alertDialog,
              open: false,
            });
          },
          handleClose: null,
        });
      }
    }
  };

  const handleCancelOrder = async (cont: string) => {
    const params = {
      seqArray: order!,
      cancCont: cont,
    };

    try {
      const result = await cancel.mutateAsync(params);
      setAlertDialog({
        open: true,
        text: "취소처리 되었습니다.",
        type: "complete",
        title: "알림",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          setCancelDialog(false);
          setOrder([]);
          refetch();
        },
        handleClose: null,
      });
    } catch (err) {
      setAlertDialog({
        open: true,
        text: "취소처리 실패했습니다.",
        type: "complete",
        title: "알림",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          setCancelDialog(false);
        },
        handleClose: null,
      });
    }
  };

  const handleDownloadExcel = async (cont: string) => {
    const parmas = {
      schStat: schForm.schStat,
      schTp: schForm.schTp,
      schFldYmd: schForm.schFldYmd,
      schStrtYmd: schForm.schStrtYmd,
      schEndYmd: schForm.schEndYmd,
      schFld: schForm.schFld,
      schTxt: schForm.schTxt,
      rsn: cont,
    };

    try {
      const result = await excel.mutateAsync(parmas);
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${"물품신청관리 리스트" + ".xlsx"}`);
      document.body.appendChild(link);
      link.click();
      setAlertDialog({
        open: true,
        text: "다운로드 되었습니다.",
        type: "complete",
        title: "알림",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          setExcelDialog(false);
        },
        handleClose: null,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleDownloadTamplate = async (cont: string) => {
    const parmas = {
      rsn: cont,
    };

    try {
      const result = await template.mutateAsync(parmas);
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${"운송장 템플릿" + ".xls"}`);
      document.body.appendChild(link);
      link.click();
      setAlertDialog({
        open: true,
        text: "다운로드 되었습니다.",
        type: "complete",
        title: "알림",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          setTemplateDialog(false);
        },
        handleClose: null,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleUploadExcel = async (file: File) => {
    try {
      const result = await upload.mutateAsync(file!);
      setAlertDialog({
        open: true,
        text: "업로드 완료했습니다.",
        type: "complete",
        title: "알림",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          setUploadDialog(false);
        },
        handleClose: null,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    if (listData.applyList.list.length > 0) {
      const newIvcNo = listData.applyList.list.map((item) => ({
        seq: item.seq,
        ivcNo: item.ivcNo,
        flag: !item.ivcNo,
      }));

      setIvcNo(newIvcNo);
    }
  }, [listData]);

  return (
    <>
      <main id="sample" css={globalCSS.listContainer} className="only-list">
        <div className="title-wrap">
          <div className="breadcrumbs">
            <div className="b-main">
              <h2>배송관리</h2>
            </div>
            <div className="b-sub">
              <span>배송관리</span>
            </div>
          </div>
        </div>
        <div className="cont-wrap">
          <div className="search-wrap">
            <table css={globalCSS.formTable}>
              <colgroup>
                <col style={{ width: "200px" }} />
                <col />
                <col style={{ width: "200px" }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <InputLabel>기간선택</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Grid container spacing={1} className="form-grid">
                      <Grid item>
                        <XTSelect
                          sx={{
                            minWidth: 150,
                          }}
                          name="schFldYmd"
                          onChange={handleChangeInput}
                          value={schViewForm.schFldYmd}
                          MenuProps={{
                            MenuListProps: { sx: { padding: 0 } },
                          }}
                        >
                          <XTMenuItem value="aplyYmd">신청일자</XTMenuItem>
                          <XTMenuItem value="tagdsYmd">출고일자</XTMenuItem>
                          <XTMenuItem value="cancYmd">취소일자</XTMenuItem>
                        </XTSelect>
                      </Grid>
                      <Grid item>
                        <XTDatePicker
                          value={changeDatepickerDate(schViewForm.schStrtYmd)}
                          onChange={handleChangeDatePicker("schStrtYmd")}
                          maxDate={changeDatepickerDate(schViewForm.schEndYmd)}
                          placeholder="시작일을 선택하세요."
                        />
                      </Grid>
                      <Grid item>
                        <div className="divider">
                          <span>~</span>
                        </div>
                      </Grid>
                      <Grid item>
                        <XTDatePicker
                          value={changeDatepickerDate(schViewForm.schEndYmd)}
                          minDate={changeDatepickerDate(schViewForm.schStrtYmd)}
                          onChange={handleChangeDatePicker("schEndYmd")}
                          placeholder="종료일을 선택하세요."
                        />
                      </Grid>
                      <Grid item>
                        <div className="date-btn-group">
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("1")}
                          >
                            1개월
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("3")}
                          >
                            3개월
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("12")}
                          >
                            1년
                          </XTButton>
                          <XTButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="xs"
                            onClick={() => handleClickDateButton("")}
                          >
                            전체
                          </XTButton>
                        </div>
                      </Grid>
                    </Grid>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel>처리상태</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <XTRadioGroup
                      name="schStat"
                      row
                      value={schViewForm.schStat}
                      onChange={handleChangeInput}
                    >
                      <XTRadio value={""} control={<Radio />} label={"전체"} />
                      {Object.keys(listData.ApplyStatus).map((key) => (
                        <XTRadio
                          key={key}
                          value={key}
                          control={<Radio />}
                          label={listData.ApplyStatus[key]}
                        />
                      ))}
                    </XTRadioGroup>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel>신청구분</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <XTRadioGroup
                      name="schTp"
                      row
                      value={schViewForm.schTp}
                      onChange={handleChangeInput}
                    >
                      <XTRadio value={""} control={<Radio />} label={"전체"} />
                      {Object.keys(listData.ApplyType).map((key) => (
                        <XTRadio
                          key={key}
                          value={key}
                          control={<Radio />}
                          label={listData.ApplyType[key]}
                        />
                      ))}
                    </XTRadioGroup>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputLabel>검색</InputLabel>
                  </th>
                  <td colSpan={3}>
                    <Grid container spacing={1} className="form-grid">
                      <Grid item>
                        <XTSelect
                          name="schFld"
                          onChange={handleChangeInput}
                          value={schViewForm.schFld}
                          MenuProps={{
                            MenuListProps: { sx: { padding: 0 } },
                          }}
                          sx={{
                            minWidth: 150,
                          }}
                        >
                          <XTMenuItem value=" ">전체</XTMenuItem>
                          <XTMenuItem value="nm">수령인</XTMenuItem>
                          <XTMenuItem value="cph">휴대폰번호</XTMenuItem>
                          <XTMenuItem value="ivcNo">운송장번호</XTMenuItem>
                        </XTSelect>
                      </Grid>
                      <Grid item>
                        <XTInput
                          name="schTxt"
                          value={schViewForm.schTxt}
                          fullWidth
                          placeholder="검색어를 입력해주세요."
                          onChange={handleChangeInput}
                          onKeyDown={handleKeyPress}
                        />
                      </Grid>
                    </Grid>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="action-button-group">
              <XTButton
                variant="outlined"
                color="primary"
                size="medium"
                onClick={() => {
                  handleResetInput();
                }}
              >
                초기화
              </XTButton>
              <XTButton
                variant="outlined"
                color="primary"
                size="medium"
                onClick={(e) => {
                  handleSearch();
                }}
              >
                조회
              </XTButton>
            </div>
          </div>
          <span className="helper-text">
            ※ 해당 메뉴는 하이케어 관리자와 연동되는 부분입니다. 데이터가
            정상적으로 노출되지 않을 시, 새로고침을 해주시기 바랍니다.
          </span>
          <div className="grid-wrap">
            <div className="total-wrap">
              <div className="total-cnt">
                전체 <span>{regComma(listData.applyList.totalSize)}</span>건
              </div>

              <Grid display="flex">
                <Grid item style={{ marginRight: "10px" }}>
                  <XTButton
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      setExcelDialog(true);
                    }}
                  >
                    엑셀 다운로드
                  </XTButton>
                </Grid>
                <Grid item>
                  <XTSelect
                    name="pgSz"
                    value={schForm.pgSz}
                    onChange={handleChangePageSz}
                    sx={{
                      minWidth: 150,
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 50,
                        horizontal: -10,
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    }}
                  >
                    <XTMenuItem value={10}>10개 씩</XTMenuItem>
                    <XTMenuItem value={20}>20개 씩</XTMenuItem>
                    <XTMenuItem value={30}>30개 씩</XTMenuItem>
                    <XTMenuItem value={50}>50개 씩</XTMenuItem>
                    <XTMenuItem value={100}>100개 씩</XTMenuItem>
                  </XTSelect>
                </Grid>
              </Grid>
            </div>

            <XTDataGrid
              loading={isFetching}
              className="clickable"
              columns={columns}
              rows={listData.applyList.list}
              getRowId={(params) => params.seq}
              apiRef={apiRef}
              experimentalFeatures={{
                ariaV7: true,
              }}
              disableRowSelectionOnClick
              onRowClick={(params) => {
                navigate(`detail/${params.row.seq}`);
              }}
              checkboxSelection
              onRowSelectionModelChange={(newSelection, details) => {
                setOrder(newSelection as number[]);
              }}
              rowSelectionModel={order}
              isRowSelectable={(params) =>
                params.row.stat !== 3 &&
                params.row.stat !== 2 &&
                params.row.divYn !== "Y"
                  ? true
                  : false
              }
              slots={{
                noRowsOverlay: () => (
                  <div className="no-data">검색 결과가 존재하지 않습니다.</div>
                ),
                noResultsOverlay: () => (
                  <div className="no-data">검색 결과가 존재하지 않습니다.</div>
                ),
                loadingOverlay: LinearProgress,
              }}
              rowHeight={38}
              columnHeaderHeight={38}
              hideFooter
            />
          </div>
          <Box css={globalCSS.bottomWrap}>
            <Pagination
              page={listData.applyList.page}
              count={listData.applyList.totalPageSize}
              onChange={handleChangePage}
            />
            <Grid container justifyContent="end" spacing={2}>
              <Grid item>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={(e) => {
                    if (order.length > 0) {
                      setCancelDialog(true);
                    } else {
                      setAlertDialog({
                        open: true,
                        text: "취소처리하실 신청내역을 선택해주세요.",
                        type: "complete",
                        title: "알림",
                        handleOK: () => {
                          setAlertDialog({
                            ...alertDialog,
                            open: false,
                          });
                        },
                        handleClose: null,
                      });
                    }
                  }}
                >
                  취소
                </XTButton>
              </Grid>
              <Grid item>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={() => {
                    if (order.length > 0) {
                      setAlertDialog({
                        open: true,
                        text: "출고처리 하시겠습니까?",
                        type: "confirm",
                        title: "알림",
                        handleOK: () => {
                          handleRegistOrder();
                        },
                        handleClose: () => {
                          setAlertDialog({
                            ...alertDialog,
                            open: false,
                          });
                        },
                      });
                    } else {
                      setAlertDialog({
                        open: true,
                        text: "출고처리하실 신청내역을 선택해주세요.",
                        type: "complete",
                        title: "알림",
                        handleOK: () => {
                          setAlertDialog({
                            ...alertDialog,
                            open: false,
                          });
                        },
                        handleClose: null,
                      });
                    }
                  }}
                >
                  출고
                </XTButton>
              </Grid>
              <Grid item>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={(e) => {
                    setUploadDialog(true);
                  }}
                >
                  운송장 업로드
                </XTButton>
              </Grid>
              <Grid item>
                <XTButton
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={(e) => {
                    setTemplateDialog(true);
                  }}
                >
                  운송장 템플릿 다운로드
                </XTButton>
              </Grid>
            </Grid>
          </Box>
        </div>
        <CancelDialog
          open={cancelDialog}
          setOpen={setCancelDialog}
          handleOK={handleCancelOrder}
        />
        <ExcelDialog
          open={excelDialog}
          setOpen={setExcelDialog}
          onChange={handleDownloadExcel}
        />
        <UploadDialog
          open={uploadDialog}
          setOpen={setUploadDialog}
          onChange={handleUploadExcel}
        />
        <TemplateDialog
          open={templateDialog}
          setOpen={setTemplateDialog}
          onChange={handleDownloadTamplate}
        />
      </main>
    </>
  );
}
