import globalCSS from "@/assets/ts/global-css";
import { XTButton, XTTextUnderlineButton } from "@/components/mui/xt-button";
import { XTFormHelperText, XTInput } from "@/components/mui/xt-input";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Grid, InputLabel, css, Radio } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useNurseDetail } from "./_hook/use-nurse";
import { useRecoilState } from "recoil";
import { alertDialogState } from "@/state";
import DaumAddress from "./_component/daum-address-dialog";
import React, { useEffect, useState } from "react";
import MemberInquiryDialog from "./_component/member-inquiry-dialog";
import { ProductListType } from "@/types/product";
import {
  formatYmdHisDot,
  regOnlyNumber,
  regOnlyPrice,
  regDeleteSpecial,
} from "@/assets/ts/common";
import { StringLocale } from "yup/lib/locale";
import CopyToClipboard from "react-copy-to-clipboard";
import CancelDialog from "@/pages/nurse/_component/cancel-dialog";
import theme from "@/assets/ts/theme";
import { NurseRegistParamsType, NurseUpdateParamsType } from "@/types/nurse";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { XTRadio, XTRadioGroup } from "@/components/mui/xt-radio";

export default function NurseDetail() {
  const { pathname, state } = useLocation();

  const {
    seq,
    navigate,
    detailData,
    productListData,
    regist,
    update,
    nurseCancel,
  } = useNurseDetail();
  //알럿호출
  const [cancelDialog, setCancelDialog] = useState(false);
  const [alertDialog, setAlertDialog] = useRecoilState(alertDialogState);
  //다음api 주소찾기
  const [daum, setDaum] = useState(false);
  //회원검색
  const [member, setMember] = useState(false);
  //회원명
  const [memberNm, setMemberNm] = useState("");

  const schema = yup.object().shape({
    aplyrTp: yup.string(),
    mcSeq: yup.mixed().when("aplyrTp", {
      is: "2",
      then: yup.string().required("회원을 조회해주세요."),
      otherwise: yup.string(),
    }),
    applyPrivacy: yup.object().shape({
      nm: yup.string().required("수령인을 입력해주세요."),
      cph: yup.string().required("수령인 휴대폰번호를 입력해주세요."),
      addr: yup.string().required("배송지를 입력해주세요."),
    }),
    applyGoodsList: yup.array().test({
      name: "qtyNotEmpty",
      message: "최소 1개 이상의 수량을 입력해주세요.",
      test: (value: any) => {
        // 모든 상품의 수량이 비어 있거나 0이 아닌지 확인합니다.
        return value.some(
          (item: { seq: number; qty: string }) => parseInt(item.qty) >= 1
        );
      },
    }),
  });

  const {
    trigger,
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
    reset,
    clearErrors,
    formState: { errors, submitCount },
  } = useForm({
    shouldFocusError: false,
    defaultValues: {
      aplyrTp: "2",
      mcSeq: "",
      mpSeq: "",
      applyPrivacy: {
        nm: "",
        cph: "",
        pst: "",
        addr: "",
        addrDtl: "",
      },
      applyGoodsList: [
        {
          ggSeq: 0,
          qty: "0",
        },
      ],
    },
    resolver: yupResolver(schema),
  });

  const handleRegistNurse = async () => {
    const data = getValues();

    const applyGoodsList = data.applyGoodsList.filter(
      (item) => item.qty && parseInt(item.qty) > 0
    );

    const form: NurseRegistParamsType = {
      ...data,
      mcSeq: data.aplyrTp === "2" ? data.mcSeq : "",
      mpSeq: data.aplyrTp === "2" ? data.mpSeq : "",
      applyGoodsList,
    };

    try {
      const result = await regist.mutateAsync(form);
      setAlertDialog({
        open: true,
        title: "Success",
        text: "물품신청 완료되었습니다.",
        type: "complete",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          navigate(state && state.prevPath ? state.prevPath : "/nurse");
        },
        handleClose: null,
      });
      // Handle success
    } catch (error) {
      setAlertDialog({
        open: true,
        title: "Success",
        text: "물품신청을 실패했습니다.",
        type: "complete",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
        },
        handleClose: null,
      });

      console.error("Mutation error:", error);
      // Handle error
    }
  };

  const handleUpdateNurse = async () => {
    const data = getValues();

    const applyGoodsList = data.applyGoodsList.filter(
      (item) => item.qty && parseInt(item.qty) > 0
    );

    const form: NurseUpdateParamsType = {
      applyPrivacy: data.applyPrivacy,
      applyGoodsList,
      seq: seq as string,
    };

    try {
      const result = await update.mutateAsync(form);
      setAlertDialog({
        ...alertDialog,
        open: true,
        title: "Success",
        text: "수정 완료되었습니다.",
        type: "complete",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          navigate(state && state.prevPath ? state.prevPath : "/nurse");
        },
      });
    } catch (error) {
      setAlertDialog({
        ...alertDialog,
        open: true,
        title: "Success",
        text: "수정을 실패했습니다.",
        type: "complete",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
        },
      });
    }
  };

  const handleCancelOrder = async (cont: string) => {
    const params = {
      seqArray: [seq!],
      cancCont: cont,
    };

    try {
      const result = await nurseCancel.mutateAsync(params);
      setAlertDialog({
        open: true,
        title: "Success",
        text: "취소처리 되었습니다.",
        type: "complete",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          navigate(state && state.prevPath ? state.prevPath : "/nurse");
        },
        handleClose: null,
      });
      // Handle success
    } catch (error) {
      setAlertDialog({
        open: true,
        text: "취소처리 실패했습니다.",
        type: "complete",
        title: "알림",
        handleOK: () => {
          setAlertDialog({
            ...alertDialog,
            open: false,
          });
          setCancelDialog(false);
        },
        handleClose: null,
      });
      // Handle error
    }
  };

  const handleSetAddress = (data: {
    zonecode: string;
    roadAddress: string;
  }) => {
    setValue("applyPrivacy.pst", data.zonecode);
    setValue("applyPrivacy.addr", data.roadAddress);
    trigger("applyPrivacy.addr");
  };

  const handleSetMember = (member: {
    chldrNm: string;
    chldrSeq: string;
    prntList: Array<{
      prntSeq: string;
    }>;
  }) => {
    setMemberNm(member.chldrNm);
    setValue("mcSeq", member.chldrSeq);
    setValue("mpSeq", member.prntList[0].prntSeq);
    trigger("mcSeq");
  };

  const onSubmit = async () => {
    setAlertDialog({
      open: true,
      title: "Success",
      text: !seq
        ? "물품을 신청 하시겠습니까?"
        : "물품 신청내역을 수정 하시겠습니까?",
      type: "confirm",
      handleOK: () => {
        if (!seq) {
          handleRegistNurse();
        } else {
          handleUpdateNurse();
        }
      },
      handleClose: () => {
        setAlertDialog({
          ...alertDialog,
          open: false,
        });
      },
    });
  };

  const onError = () => {};

  const getApplyrNm = () => {
    let nm: string = "";

    if (detailData?.apply?.aplyrTp === 1 && detailData?.apply?.applyPrivacy) {
      nm = detailData?.apply?.applyPrivacy?.nm
        ? detailData?.apply?.applyPrivacy?.nm
        : "-";
    } else if (
      detailData?.apply?.aplyrTp === 2 &&
      detailData?.apply?.aplyrManagerAccount
    ) {
      nm = detailData?.apply?.aplyrManagerAccount?.nm
        ? `${detailData?.apply?.aplyrManagerAccount?.nm}(${detailData?.apply?.aplyrManagerAccount?.id})`
        : "-";
    } else if (detailData?.apply?.aplyrTp === 3) {
      nm = "채널";
    } else if (detailData?.apply?.aplyrTp === 4) {
      nm = "비회원";
    } else {
      nm = "-";
    }

    return nm;
  };

  useEffect(() => {
    if (detailData) {
      reset({
        aplyrTp: String(detailData?.apply?.aplyrTp),
        mcSeq: detailData?.apply?.mcSeq,
        mpSeq: detailData?.apply?.mpSeq,
        applyPrivacy: {
          nm: detailData?.apply?.applyPrivacy?.nm ?? "-",
          cph: detailData?.apply?.applyPrivacy?.cph ?? "-",
          pst: detailData?.apply?.applyPrivacy?.pst ?? "-",
          addr: detailData?.apply?.applyPrivacy?.addr ?? "-",
          addrDtl: detailData?.apply?.applyPrivacy?.addrDtl ?? "",
        },
        applyGoodsList: productListData.goodsList.map(
          (product: ProductListType["goodsList"][0]) => {
            const matchedObject = detailData?.apply?.applyGoodsList.find(
              (item: { ggSeq: number }) => item.ggSeq === product.seq
            );

            return {
              ggSeq: product.seq,
              qty: matchedObject ? matchedObject.qty : "0",
            };
          }
        ),
      });

      setMemberNm(detailData?.apply?.applyPrivacy?.chldrNm ?? "-");
    }
  }, [detailData]);

  useEffect(() => {
    if (productListData && !seq) {
      setValue(
        "applyGoodsList",
        productListData.goodsList.map(
          (product: ProductListType["goodsList"][0]) => {
            return {
              ggSeq: product.seq,
              qty: "",
            };
          }
        )
      );
    }
  }, [productListData]);

  return (
    <>
      <main id="sample" css={[globalCSS.listContainer, style.container]}>
        <div className="title-wrap">
          <div className="breadcrumbs">
            <div className="b-main">
              <h2>물품신청</h2>
            </div>
            <div className="b-sub">
              <span>물품신청</span>
              <span>물품신청 상세</span>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="cont-wrap">
            {seq && (
              <div className="cont-inner">
                <h3 className="sub-title">물품신청상태</h3>
                <table css={globalCSS.formTable}>
                  <colgroup>
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "40%" }} />
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "40%" }} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <InputLabel>처리상태</InputLabel>
                      </th>
                      <td colSpan={3}>
                        {detailData?.apply?.stat != 3
                          ? detailData?.ApplyStatus[detailData?.apply?.stat]
                          : detailData?.ApplyCancelType[
                              detailData?.apply?.cancrTp
                            ] +
                            " " +
                            detailData?.ApplyStatus[detailData?.apply?.stat]}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <InputLabel>신청자</InputLabel>
                      </th>
                      <td>{getApplyrNm()}</td>
                      <th scope="row">
                        <InputLabel>신청일시</InputLabel>
                      </th>
                      <td>
                        {formatYmdHisDot(
                          detailData?.apply?.aplyYmd,
                          detailData?.apply?.aplyHis
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <InputLabel>운송장번호</InputLabel>
                      </th>
                      <td>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>
                            <div>
                              {detailData?.apply?.ivcNo
                                ? detailData?.apply?.ivcNo
                                : "-"}
                            </div>
                          </Grid>
                          {detailData?.apply?.ivcNo && (
                            <>
                              <Grid item>
                                <XTButton
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    window.open(
                                      `https://www.cjlogistics.com/ko/tool/parcel/tracking?gnbInvcNo=${detailData?.apply?.ivcNo}`,
                                      "_blank"
                                    );
                                  }}
                                >
                                  조회
                                </XTButton>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </td>
                      <th scope="row">
                        <InputLabel>출고일시</InputLabel>
                      </th>
                      <td>
                        {formatYmdHisDot(
                          detailData?.apply?.tagdsYmd,
                          detailData?.apply?.tagdsHis
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <InputLabel>취소사유</InputLabel>
                      </th>
                      <td>
                        {detailData?.apply?.cancCont
                          ? detailData?.apply?.cancCont
                          : "-"}
                      </td>
                      <th scope="row">
                        <InputLabel>취소일시</InputLabel>
                      </th>
                      <td>
                        {formatYmdHisDot(
                          detailData?.apply?.cancYmd,
                          detailData?.apply?.cancHis
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            <div className="cont-inner">
              <h3 className="sub-title">
                수령인 정보(<span className="color-point">*</span> 필수항목)
              </h3>
              <table css={globalCSS.formTable}>
                <colgroup>
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "40%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <InputLabel required>신청대상구분</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Controller
                        name="aplyrTp"
                        control={control}
                        render={({ field }) => (
                          <XTRadioGroup row {...field}>
                            <XTRadio
                              disabled={!!seq}
                              value={"2"}
                              control={<Radio />}
                              label={"회원"}
                            />
                            <XTRadio
                              disabled={!!seq}
                              value={"4"}
                              control={<Radio />}
                              label={"비회원"}
                            />
                          </XTRadioGroup>
                        )}
                      />
                    </td>
                  </tr>
                  {!!seq || (!seq && watch("aplyrTp") !== "4") ? (
                    <tr>
                      <th scope="row">
                        <InputLabel required>자녀이름</InputLabel>
                      </th>
                      <td colSpan={3}>
                        <Grid container spacing={1}>
                          <Grid item>
                            <Controller
                              rules={{
                                required: true,
                              }}
                              name="mcSeq"
                              control={control}
                              render={({ field }) => (
                                <div className="input-length">
                                  <XTInput
                                    {...field}
                                    sx={{
                                      minWidth: 310,
                                    }}
                                    value={memberNm}
                                    disabled
                                    placeholder="회원을 조회해주세요."
                                  />
                                </div>
                              )}
                            />
                          </Grid>
                          {!seq && (
                            <Grid item>
                              <XTButton
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => {
                                  setMember(true);
                                }}
                              >
                                회원조회
                              </XTButton>
                            </Grid>
                          )}
                        </Grid>
                        {errors.mcSeq?.type === "required" && (
                          <XTFormHelperText className="error">
                            {errors.mcSeq?.message}
                          </XTFormHelperText>
                        )}
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  <tr>
                    <th scope="row">
                      <InputLabel required>수령인</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Controller
                        rules={{
                          required: true,
                        }}
                        name="applyPrivacy.nm"
                        control={control}
                        render={({ field }) => (
                          <div className="input-length">
                            <XTInput
                              {...field}
                              sx={{
                                minWidth: 310,
                              }}
                              disabled={
                                detailData?.apply?.divYn === "Y" ||
                                detailData?.apply?.stat == 2 ||
                                detailData?.apply?.stat == 3 ||
                                detailData?.apply?.ivcRgstYn === "Y"
                              }
                              placeholder="수령인을 입력해 주세요."
                              onChange={(e) => {
                                field.onChange(
                                  regDeleteSpecial(e.target.value)
                                );
                              }}
                            />
                          </div>
                        )}
                      />
                      {errors.applyPrivacy?.nm?.type === "required" && (
                        <XTFormHelperText className="error">
                          {errors.applyPrivacy?.nm?.message}
                        </XTFormHelperText>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <InputLabel required>수령인 휴대폰번호</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Controller
                        name="applyPrivacy.cph"
                        control={control}
                        render={({ field }) => (
                          <div className="input-length">
                            <XTInput
                              {...field}
                              sx={{
                                minWidth: 310,
                              }}
                              disabled={
                                detailData?.apply?.divYn === "Y" ||
                                detailData?.apply?.stat == 2 ||
                                detailData?.apply?.stat == 3 ||
                                detailData?.apply?.ivcRgstYn === "Y"
                              }
                              placeholder="수령인 휴대폰번호를 입력해 주세요."
                              inputProps={{ maxLength: 11 }}
                              onChange={(e) => {
                                if (/^\d*$/.test(e.target.value)) {
                                  setValue("applyPrivacy.cph", e.target.value);
                                  trigger("applyPrivacy.cph");
                                }
                              }}
                            />
                          </div>
                        )}
                      />
                      {errors.applyPrivacy?.cph?.type === "required" && (
                        <XTFormHelperText className="error">
                          {errors.applyPrivacy?.cph?.message}
                        </XTFormHelperText>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <th scope="row" rowSpan={3}>
                      <InputLabel required>배송지</InputLabel>
                    </th>
                    <td colSpan={3}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <XTInput
                            value={watch("applyPrivacy.pst")}
                            disabled
                            placeholder="우편번호를 입력해주세요."
                            sx={{
                              minWidth: 310,
                            }}
                          />
                        </Grid>
                        {detailData?.apply?.divYn !== "Y" &&
                          detailData?.apply?.stat != 2 &&
                          detailData?.apply?.stat != 3 &&
                          detailData?.apply?.ivcRgstYn !== "Y" && (
                            <Grid item>
                              <XTButton
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => {
                                  setDaum(true);
                                }}
                              >
                                우편번호 찾기
                              </XTButton>
                            </Grid>
                          )}
                      </Grid>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <div className="input-length">
                        <XTInput
                          disabled
                          value={watch("applyPrivacy.addr")}
                          sx={{
                            minWidth: 310,
                          }}
                          placeholder="주소를 입력해 주세요."
                        />
                      </div>
                      {errors.applyPrivacy?.addr?.type === "required" && (
                        <XTFormHelperText className="error">
                          {errors.applyPrivacy?.addr?.message}
                        </XTFormHelperText>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <Controller
                        rules={{
                          required: true,
                        }}
                        name="applyPrivacy.addrDtl"
                        control={control}
                        render={({ field }) => (
                          <div className="input-length">
                            <XTInput
                              {...field}
                              sx={{
                                minWidth: 310,
                              }}
                              disabled={
                                detailData?.apply?.divYn === "Y" ||
                                detailData?.apply?.stat == 2 ||
                                detailData?.apply?.stat == 3 ||
                                detailData?.apply?.ivcRgstYn === "Y"
                              }
                              placeholder="상세 주소를 입력해 주세요.(선택)"
                            />
                          </div>
                        )}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {productListData && (
              <div className="cont-inner">
                <h3 className="sub-title">
                  신청품목 정보(<span className="color-point">*</span> 필수항목)
                </h3>
                <table css={globalCSS.formTable}>
                  <colgroup>
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "40%" }} />
                    <col style={{ width: "50%" }} />
                  </colgroup>
                  <tbody>
                    {productListData.goodsList.map(
                      (product: ProductListType["goodsList"][0], i: number) => (
                        <tr key={i}>
                          <th scope="row">
                            <InputLabel required>{product.nm}</InputLabel>
                          </th>
                          <td colSpan={2}>
                            <Controller
                              rules={{
                                required: true,
                              }}
                              name={`applyGoodsList.${i}.qty`}
                              control={control}
                              render={({ field }) => (
                                <Grid container>
                                  <Grid item>
                                    <XTButton
                                      disabled={
                                        detailData?.apply?.divYn === "Y" ||
                                        detailData?.apply?.stat == 2 ||
                                        detailData?.apply?.stat == 3 ||
                                        detailData?.apply?.ivcRgstYn === "Y"
                                      }
                                      color="primary"
                                      size="small"
                                      className="num-btn"
                                      onClick={() => {
                                        if (
                                          watch(`applyGoodsList.${i}.qty`) &&
                                          parseInt(
                                            watch(`applyGoodsList.${i}.qty`)
                                          ) > 0
                                        ) {
                                          let num =
                                            parseInt(
                                              watch(`applyGoodsList.${i}.qty`)
                                            ) - 1;
                                          setValue(
                                            `applyGoodsList.${i}.qty`,
                                            num.toString()
                                          );
                                        }
                                        trigger(`applyGoodsList`);
                                      }}
                                    >
                                      <RemoveIcon />
                                    </XTButton>
                                  </Grid>
                                  <Grid item>
                                    <input
                                      value={
                                        watch(`applyGoodsList.${i}.qty`)
                                          ? watch(`applyGoodsList.${i}.qty`)
                                          : "0"
                                      }
                                      disabled={
                                        detailData?.apply?.divYn === "Y" ||
                                        detailData?.apply?.stat == 2 ||
                                        detailData?.apply?.stat == 3 ||
                                        detailData?.apply?.ivcRgstYn === "Y"
                                      }
                                      className="num-input"
                                      placeholder="수량을 입력해주세요."
                                      type="text"
                                      onChange={(e) => {
                                        const value = e.target.value;

                                        setValue(
                                          `applyGoodsList.${i}.qty`,
                                          regOnlyPrice(value)
                                        );
                                        trigger(`applyGoodsList`);
                                      }}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <XTButton
                                      disabled={
                                        detailData?.apply?.divYn === "Y" ||
                                        detailData?.apply?.stat == 2 ||
                                        detailData?.apply?.stat == 3 ||
                                        detailData?.apply?.ivcRgstYn === "Y"
                                      }
                                      className="num-btn"
                                      color="primary"
                                      size="small"
                                      onClick={() => {
                                        if (
                                          watch(`applyGoodsList.${i}.qty`) == ""
                                        ) {
                                          setValue(
                                            `applyGoodsList.${i}.qty`,
                                            "1"
                                          );
                                        } else {
                                          let num =
                                            parseInt(
                                              watch(`applyGoodsList.${i}.qty`)
                                            ) + 1;
                                          setValue(
                                            `applyGoodsList.${i}.qty`,
                                            num.toString()
                                          );
                                        }
                                        trigger(`applyGoodsList`);
                                      }}
                                    >
                                      <AddIcon />{" "}
                                    </XTButton>
                                  </Grid>
                                </Grid>
                              )}
                            />
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>

                <p className="notetext">
                  ※ 물품신청 시 니들 Box당 7개, 알콜솜 Box당 10매가 발송됩니다.
                </p>
                {errors.applyGoodsList?.type === "qtyNotEmpty" && (
                  <XTFormHelperText className="error">
                    {errors.applyGoodsList?.message}
                  </XTFormHelperText>
                )}
              </div>
            )}
            <Box css={globalCSS.bottomWrap} marginTop={4}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <XTButton
                    variant="outlined"
                    color="primary"
                    size="medium"
                    onClick={() => {
                      navigate(
                        state && state.prevPath ? state.prevPath : "/nurse"
                      );
                    }}
                  >
                    {seq ? "목록" : "취소"}
                  </XTButton>
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    {!seq ? (
                      <Grid item>
                        <XTButton
                          variant="outlined"
                          color="primary"
                          size="medium"
                          type="submit"
                        >
                          물품신청
                        </XTButton>
                      </Grid>
                    ) : (
                      detailData?.apply?.stat == 1 &&
                      detailData?.apply?.divYn !== "Y" && (
                        <>
                          <Grid item>
                            <XTButton
                              variant="outlined"
                              color="primary"
                              size="medium"
                              onClick={() => {
                                setCancelDialog(true);
                              }}
                            >
                              물품신청 취소
                            </XTButton>
                          </Grid>
                          {detailData?.apply?.ivcRgstYn == "N" && (
                            <Grid item>
                              <XTButton
                                variant="outlined"
                                color="primary"
                                size="medium"
                                type="submit"
                              >
                                물품신청 수정
                              </XTButton>
                            </Grid>
                          )}
                        </>
                      )
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </div>
        </form>
        <MemberInquiryDialog
          openDialog={member}
          setOpenDialog={setMember}
          onChange={handleSetMember}
        />
        {daum && (
          <DaumAddress
            openDialog={daum}
            setOpenDialog={setDaum}
            onChange={handleSetAddress}
          />
        )}
        <CancelDialog
          open={cancelDialog}
          setOpen={setCancelDialog}
          handleOK={handleCancelOrder}
        />
      </main>
    </>
  );
}

const style = {
  container: css`
    .num-btn {
      &:disabled {
        color: ${theme.palette.text_sub2};
      }
    }

    .num-input {
      padding: 10px;
      width: 12.5rem;
      height: 1.8125rem;
      border: 1px solid #ccceca;
      border-left: none;
      border-right: none;

      ::placeholder {
        font-size: 14px;
        color: #ccceca;
      }

      &:disabled {
        background: ${theme.palette.disabled};
        color: ${theme.palette.text_sub2};

        ::placeholder {
          color: ${theme.palette.text_sub2};
        }
      }
    }

    .notetext {
      margin-top: 10px;
      font-size: 13px;
      color: #999999;
    }
  `,
};
