import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import {
  cancelNurseAPI,
  loadNurseDetailAPI,
  loadNurseListAPI,
  registNurseAPI,
  updateNurseAPI,
} from "@/api/nurse";
import { changeSubmitDate } from "@/assets/ts/common";
import moment from "moment";
import { AxiosError, AxiosResponse } from "axios";
import {
  NurseListSchFormType,
  NurseListType,
  NurseOrderCancelParamsType,
} from "@/types/nurse";
import { loadCommonProductListAPI, loadProductListAPI } from "@/api/product";
import { loadChildListAPI } from "@/api/member/child";
import { ChildListType } from "@/types/member/child";
import { SelectChangeEvent } from "@mui/material";
import { OrderNumberParamsType } from "@/types/order";
import { registOrderNumberAPI } from "@/api/order";
import { CollectionsBookmarkOutlined } from "@mui/icons-material";

export const useNurseList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  const initialSchFormData: NurseListSchFormType = {
    pg: params.get("pg") || "1",
    pgSz: params.get("pgSz") || "10",
    sordFld: params.get("sordFld") || "",
    sordMetd: params.get("sordMetd") || "DESC",
    schFldYmd: params.get("schFldYmd") || "aplyYmd",
    schStrtYmd: params.get("schStrtYmd") || "",
    schEndYmd: params.get("schEndYmd") || "",
    schStat: params.get("schStat") || "",
    schFld: params.get("schFld") || " ",
    schTxt: params.get("schTxt") || "",
    schTp: params.get("schTp") || "",
  };
  const [schForm, setSchForm] = useState(initialSchFormData);
  const [order, setOrder] = useState<number[]>([]);

  const [schViewForm, setSchViewForm] = useState({
    schFldYmd: params.get("schFldYmd") || "aplyYmd",
    schStrtYmd: params.get("schStrtYmd") || "",
    schEndYmd: params.get("schEndYmd") || "",
    schStat: params.get("schStat") || "",
    schFld: params.get("schFld") || " ",
    schTxt: params.get("schTxt") || "",
    schTp: params.get("schTp") || "",
  });

  const initialData: NurseListType = {
    applyList: {
      list: [],
      pageSize: 0,
      totalSize: 0,
      idx: 0,
      page: 0,
      number: 0,
      nextPageAvailable: false,
      previousPageAvailable: false,
      firstPage: false,
      pageIndex: 0,
      middlePage: false,
      lastPage: false,
      totalPageSize: 0,
      empty: false,
    },
    ApplyStatus: {
      "1": "신청",
      "2": "출고",
      "3": "취소",
    },
    ApplyType: {
      "1": "사용자",
      "2": "간호사",
      "3": "채널",
    },
    ApplyCancelType: {
      "1": "사용자",
      "2": "관리자",
      "3": "간호사",
    },
  };

  const {
    data: listData = initialData,
    isFetching,
    refetch,
  } = useQuery<
    AxiosResponse<{ data: NurseListType }>,
    AxiosError,
    NurseListType
  >({
    queryKey: ["loadNurseList", schForm],
    queryFn: async () => await loadNurseListAPI(schForm),
    select: (res) => {
      let list = res.data.data.applyList.list;

      list.map((item, i) => {
        item.id = item.seq;
        item.index = i;
      });

      return res.data.data;
    },
  });

  const handleChangeInput = (event: any) => {
    const target = event.target;
    const name = target.name;

    setSchViewForm((prev) => ({
      ...prev,
      [name]: target.value,
    }));
  };

  const handleChangePage = async (
    e: React.ChangeEvent<unknown>,
    value: number
  ) => {
    let form = {
      ...schForm,
      pg: value.toString(),
    };
    setSchForm(form);

    const queryStringResult = queryString.stringify(form);
    navigate("/nurse?" + queryStringResult);
  };

  const handleChangePageSz = (event: SelectChangeEvent<unknown>) => {
    const target = event.target;
    const name = target.name;

    let form = {
      ...schForm,
      pg: "1",
      [name]: target.value,
    };
    setSchForm(form);

    const queryStringResult = queryString.stringify(form);
    navigate("/nurse?" + queryStringResult);
  };

  const handleResetInput = () => {
    let resetForm = {
      pg: "1",
      pgSz: "10",
      sordFld: "rgstYmd",
      sordMetd: "DESC",
      schFldYmd: "aplyYmd",
      schStrtYmd: "",
      schEndYmd: "",
      schStat: "",
      schFld: " ",
      schTxt: "",
      schTp: "",
    };

    setSchForm(resetForm);

    setSchViewForm({
      schFldYmd: "aplyYmd",
      schStrtYmd: "",
      schEndYmd: "",
      schStat: "",
      schFld: " ",
      schTxt: "",
      schTp: "",
    });
    const queryStringResult = queryString.stringify(resetForm);
    navigate("/nurse?" + queryStringResult);
  };

  const handleChangeDatePicker = (name: string) => (value: Date) => {
    setSchViewForm((prev) => ({
      ...prev,
      pg: "1",
      [name]: changeSubmitDate(value),
    }));
  };

  const handleClickDateButton = (month: string | null) => {
    let startDate = "";
    let endDate = "";

    if (month) {
      startDate = moment().subtract(month, "months").format("YYYY-MM-DD");
      endDate = moment().format("YYYY-MM-DD");
    }

    setSchViewForm((prev) => ({
      ...prev,
      pg: "1",
      schStrtYmd: changeSubmitDate(startDate),
      schEndYmd: changeSubmitDate(endDate),
    }));
  };

  const handleSearch = () => {
    let form = {
      ...schForm,
      schFldYmd: schViewForm.schFldYmd,
      schStrtYmd: schViewForm.schStrtYmd,
      schEndYmd: schViewForm.schEndYmd,
      schStat: schViewForm.schStat,
      schFld: schViewForm.schFld,
      schTxt: schViewForm.schTxt,
      schTp: schViewForm.schTp,
      pg: "1",
      pgSz: "10",
    };
    setSchForm(form);
    const queryStringResult = queryString.stringify(form);
    navigate("/nurse?" + queryStringResult);
  };

  useEffect(() => {
    setSchForm({
      ...initialSchFormData,
    });
    setSchViewForm({
      ...initialSchFormData,
    });
  }, [location.search]);

  return {
    listData,
    schForm,
    schViewForm,
    isFetching,
    refetch,
    order,
    setOrder,
    handleResetInput,
    handleChangeInput,
    handleChangePage,
    handleChangePageSz,
    handleChangeDatePicker,
    handleClickDateButton,
    navigate,
    handleSearch,
  };
};

export const useNurseDetail = () => {
  let { seq } = useParams();
  const navigate = useNavigate();

  const { data: detailData } = useQuery({
    queryKey: ["loadNurseDetail", seq],
    queryFn: async () => await loadNurseDetailAPI(seq!),
    select: (res) => {
      return res.data.data;
    },
    enabled: seq ? true : false,
  });

  const initialData = {
    goodsList: [],
  };

  const { data: productListData = initialData } = useQuery({
    queryKey: ["loadNurseProductList"],
    queryFn: async () => await loadCommonProductListAPI(),
    select: (res) => {
      return res.data.data;
    },
  });

  const regist = useMutation({
    mutationFn: (form: any) => registNurseAPI(form),
  });

  const update = useMutation({
    mutationFn: (form: any) => updateNurseAPI(form),
  });

  const nurseCancel = useMutation({
    mutationFn: (form: NurseOrderCancelParamsType) => cancelNurseAPI(form),
  });

  const number = useMutation({
    mutationFn: (form: OrderNumberParamsType) => registOrderNumberAPI(form),
  });

  return {
    seq,
    navigate,
    detailData,
    productListData,
    regist,
    update,
    nurseCancel,
    number,
  };
};

export const useMemberList = () => {
  const [schForm, setSchForm] = useState({
    pg: "1",
    pgSz: "5",
    sordFld: "seq",
    sordMetd: "DESC",
    schFldYmd: "gds_tagds",
    schStrtYmd: "",
    schEndYmd: "",
    schFld: "chldr_nm",
    schTxt: "",
  });

  const [schViewForm, setSchViewForm] = useState({
    schFld: "chldr_nm",
    schTxt: "",
  });

  const initialData: ChildListType = {
    innoBoostChildList: {
      list: [],
      pageSize: 0,
      totalSize: 0,
      idx: 0,
      page: 0,
      number: 0,
      nextPageAvailable: false,
      previousPageAvailable: false,
      firstPage: false,
      pageIndex: 0,
      middlePage: false,
      lastPage: false,
      totalPageSize: 10,
      empty: false,
    },
  };

  const { data: listData = initialData } = useQuery<
    AxiosResponse<{ data: ChildListType }>,
    AxiosError,
    ChildListType
  >({
    queryKey: ["loadChildMemberList", schForm],
    queryFn: async () => await loadChildListAPI(schForm),
    select: (res) => {
      let list = res.data.data.innoBoostChildList.list;

      list.map((item, i) => {
        item.index = i;
      });

      return res.data.data;
    },
  });

  const handleChangeInput = (event: any) => {
    const target = event.target;
    const name = target.name;

    setSchViewForm((prev) => ({
      ...prev,
      [name]: target.value,
    }));
  };

  const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
    let form = {
      ...schForm,
      pg: value.toString(),
    };
    setSchForm(form);
  };

  const handleSearch = () => {
    let form = {
      ...schForm,
      schFld: schViewForm.schFld,
      schTxt: schViewForm.schTxt,
    };

    setSchForm(form);
  };

  const handleResetInput = () => {
    let resetForm = {
      pg: "1",
      pgSz: "5",
      sordFld: "seq",
      sordMetd: "DESC",
      schFldYmd: "gds_tagds",
      schStrtYmd: "",
      schEndYmd: "",
      schFld: "chldr_nm",
      schTxt: "",
    };

    setSchForm(resetForm);

    setSchViewForm({
      schFld: "chldr_nm",
      schTxt: "",
    });
  };

  return {
    listData,
    schViewForm,
    handleChangeInput,
    handleChangePage,
    handleSearch,
    handleResetInput,
  };
};
