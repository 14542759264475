import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import { changeSubmitDate } from "@/assets/ts/common";
import moment from "moment";
import {
  cancelOrderAPI,
  downloadExcelOrderAPI,
  downloadOrderAPI,
  loadOrderListAPI,
  loadOrderTypesAPI,
  orderDetailAPI,
  registOrderAPI,
  registOrderNumberAPI,
  uploadOrderAPI,
} from "@/api/order";
import {
  ExcelDownloadParamsType,
  OrderCancelParamsType,
  OrderDownloadParamsType,
  OrderListSchFormType,
  OrderListType,
  OrderNumberParamsType,
  OrderRegistParamsType,
  OrderStatusType,
} from "@/types/order";
import { AxiosError, AxiosResponse } from "axios";
import { SelectChangeEvent } from "@mui/material";

export const useOrderType = () => {
  const initialData = {
    ApplyStatus: {
      "1": "신청",
      "2": "출고",
      "3": "취소",
    },
    ApplyType: {
      "1": "사용자",
      "2": "간호사",
      "3": "채널",
    },
    ApplyCancelType: {
      "1": "사용자",
      "2": "관리자",
      "3": "간호사",
    },
  };

  const { data: orderTypes = initialData } = useQuery<
    AxiosResponse<{
      data: OrderStatusType;
    }>,
    AxiosError,
    OrderStatusType
  >({
    queryKey: ["loadOrderTypes"],
    queryFn: async () => await loadOrderTypesAPI(),
    select: (res) => {
      return res.data.data;
    },
  });

  return { orderTypes };
};

export const useOrderList = () => {
  const [order, setOrder] = useState<number[]>([]);
  const [ivcNo, setIvcNo] = useState<any[]>([]);

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const initialSchFormData: OrderListSchFormType = {
    pg: params.get("pg") || "1",
    pgSz: params.get("pgSz") || "10",
    sordFld: params.get("sordFld") || "",
    sordMetd: params.get("sordMetd") || "DESC",
    schFldYmd: params.get("schFldYmd") || "aplyYmd",
    schStrtYmd: params.get("schStrtYmd") || "",
    schEndYmd: params.get("schEndYmd") || "",
    schStat: params.get("schStat") || "",
    schTp: params.get("schTp") || "",
    schFld: params.get("schFld") || " ",
    schTxt: params.get("schTxt") || "",
  };

  const [schForm, setSchForm] = useState({
    pg: params.get("pg") || "1",
    pgSz: params.get("pgSz") || "10",
    sordFld: params.get("sordFld") || "",
    sordMetd: params.get("sordMetd") || "DESC",
    schFldYmd: params.get("schFldYmd") || "aplyYmd",
    schStrtYmd: params.get("schStrtYmd") || "",
    schEndYmd: params.get("schEndYmd") || "",
    schStat: params.get("schStat") || "",
    schTp: params.get("schTp") || "",
    schFld: params.get("schFld") || " ",
    schTxt: params.get("schTxt") || "",
  });

  const [schViewForm, setSchViewForm] = useState({
    schFldYmd: params.get("schFldYmd") || "aplyYmd",
    schStrtYmd: params.get("schStrtYmd'") || "",
    schEndYmd: params.get("schEndYmd'") || "",
    schStat: params.get("schStat") || "",
    schTp: params.get("schTp") || "",
    schFld: params.get("schFld") || " ",
    schTxt: params.get("schTxt") || "",
  });

  const initialData: OrderListType = {
    applyList: {
      list: [],
      pageSize: 0,
      totalSize: 0,
      idx: 0,
      page: 0,
      number: 0,
      nextPageAvailable: false,
      previousPageAvailable: false,
      firstPage: false,
      pageIndex: 0,
      middlePage: false,
      lastPage: false,
      totalPageSize: 0,
      empty: false,
    },
    ApplyStatus: {
      "1": "신청",
      "2": "출고",
      "3": "취소",
    },
    ApplyType: {
      "1": "사용자",
      "2": "간호사",
      "3": "채널",
    },
    ApplyCancelType: {
      "1": "사용자",
      "2": "관리자",
      "3": "간호사",
    },
  };

  const {
    data: listData = initialData,
    isFetching,
    refetch,
  } = useQuery<
    AxiosResponse<{ data: OrderListType }>,
    AxiosError,
    OrderListType
  >({
    queryKey: ["loadOrderList", schForm],
    queryFn: async () => await loadOrderListAPI(schForm),
    select: (res) => {
      let list = res.data.data.applyList.list;

      list.map((item, i) => {
        item.id = item.seq;
        item.index = i;
      });

      return res.data.data;
    },
  });

  const handleResetInput = () => {
    let resetForm = {
      pg: "1",
      pgSz: "10",
      sordFld: "rgstYmd",
      sordMetd: "DESC",
      schStrtYmd: "",
      schEndYmd: "",
      schFldYmd: "aplyYmd",
      schStat: "",
      schTp: "",
      schFld: " ",
      schTxt: "",
    };

    setSchForm(resetForm);
    setSchViewForm({
      schFldYmd: "aplyYmd",
      schStrtYmd: "",
      schEndYmd: "",
      schStat: "",
      schTp: "",
      schFld: " ",
      schTxt: "",
    });

    const queryStringResult = queryString.stringify(resetForm);
    navigate("/order?" + queryStringResult);
  };

  const handleChangeInput = (event: any) => {
    const target = event.target;
    const name = target.name;

    setSchViewForm((prev) => ({
      ...prev,
      [name]: target.value,
    }));
  };

  const handleChangeDatePicker = (name: string) => (value: Date) => {
    setSchViewForm((prev) => ({
      ...prev,
      pg: "1",
      [name]: changeSubmitDate(value),
    }));
  };

  const handleClickDateButton = (month: string | null) => {
    let startDate = "";
    let endDate = "";

    if (month) {
      startDate = moment().subtract(month, "months").format("YYYY-MM-DD");
      endDate = moment().format("YYYY-MM-DD");
    }

    setSchViewForm((prev) => ({
      ...prev,
      pg: "1",
      schStrtYmd: changeSubmitDate(startDate),
      schEndYmd: changeSubmitDate(endDate),
    }));
  };

  const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
    let form = {
      ...schForm,
      pg: value.toString(),
    };
    setSchForm(form);

    const queryStringResult = queryString.stringify(form);
    navigate("/order?" + queryStringResult);
  };

  const handleChangePageSz = (event: SelectChangeEvent<unknown>) => {
    const target = event.target;
    const name = target.name;

    let form = {
      ...schForm,
      pg: "1",
      [name]: target.value,
    };
    setSchForm(form);

    const queryStringResult = queryString.stringify(form);
    navigate("/order?" + queryStringResult);
  };

  const handleChangeSort = (sordFld: string) => {
    let sordMetd = "";
    if (schForm.sordFld !== sordFld) {
      sordMetd = "ASC";
    } else {
      sordMetd = schForm.sordMetd === "DESC" ? "ASC" : "DESC";
    }

    let form = {
      ...schForm,
      sordMetd: sordMetd,
      sordFld: sordFld,
    };
    setSchForm(form);
    const queryStringResult = queryString.stringify(form);
    navigate("/order?" + queryStringResult);
  };

  const handleSearch = () => {
    let form = {
      ...schForm,
      schFldYmd: schViewForm.schFldYmd,
      schStrtYmd: schViewForm.schStrtYmd,
      schEndYmd: schViewForm.schEndYmd,
      schTp: schViewForm.schTp,
      schTxt: schViewForm.schTxt,
      schStat: schViewForm.schStat,
      schFld: schViewForm.schFld,
      pg: "1",
      pgSz: "10",
    };

    setSchForm(form);
    const queryStringResult = queryString.stringify(form);
    navigate("/order?" + queryStringResult);
  };

  //운송장 업로드
  const upload = useMutation({
    mutationFn: (file: any) => uploadOrderAPI(file),
  });
  //운송장 템플릿 다운로드
  const template = useMutation({
    mutationFn: (form: OrderDownloadParamsType) => downloadOrderAPI(form),
  });
  //엑셀 다운로드
  const excel = useMutation({
    mutationFn: (form: ExcelDownloadParamsType) => downloadExcelOrderAPI(form),
  });

  const regist = useMutation({
    mutationFn: (form: OrderRegistParamsType) => registOrderAPI(form),
  });

  const cancel = useMutation({
    mutationFn: (form: OrderCancelParamsType) => cancelOrderAPI(form),
  });

  const number = useMutation({
    mutationFn: (form: OrderNumberParamsType) => registOrderNumberAPI(form),
  });

  useEffect(() => {
    setSchForm({
      ...initialSchFormData,
    });
    setSchViewForm({
      ...initialSchFormData,
    });
  }, [location.search]);

  return {
    ivcNo,
    setIvcNo,
    order,
    setOrder,
    listData,
    isFetching,
    refetch,
    schForm,
    schViewForm,
    handleChangeInput,
    handleChangePage,
    handleChangePageSz,
    handleClickDateButton,
    handleResetInput,
    handleChangeDatePicker,
    handleSearch,
    handleChangeSort,
    navigate,
    regist,
    cancel,
    number,
    upload,
    excel,
    template,
  };
};

export const useOrderDetail = () => {
  let { seq } = useParams();
  const navigate = useNavigate();
  const [num, setNum] = useState("");
  const { data: detailData, refetch } = useQuery({
    queryKey: ["loadOrderDetail", seq],
    queryFn: async () => await orderDetailAPI(seq!),
    select: (res) => {
      return res.data.data;
    },

    enabled: seq ? true : false,
  });

  const regist = useMutation({
    mutationFn: (form: OrderRegistParamsType) => registOrderAPI(form),
  });

  const cancel = useMutation({
    mutationFn: (form: OrderCancelParamsType) => cancelOrderAPI(form),
  });

  const number = useMutation({
    mutationFn: (form: OrderNumberParamsType) => registOrderNumberAPI(form),
  });

  return {
    seq,
    navigate,
    detailData,
    refetch,
    regist,
    cancel,
    number,
    num,
    setNum,
  };
};
