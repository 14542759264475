import globalCSS from "@/assets/ts/global-css";
import { XTButton } from "@/components/mui/xt-button";
import { XTDataGrid } from "@/components/mui/xt-datagrid";
import {
  XTDialog,
  XTDialogActions,
  XTDialogContent,
  XTDialogTitle,
} from "@/components/mui/xt-dialog";
import { XTInput } from "@/components/mui/xt-input";
import { Box, Grid, LinearProgress, Pagination } from "@mui/material";
import {
  GridColDef,
  GridValueGetterParams,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useMemberList } from "../_hook/use-nurse";
import { XTMenuItem, XTSelect } from "@/components/mui/xt-select";
import { regPhoneNumber } from "@/assets/ts/common";

export default function MemberInquiryDialog(props: {
  openDialog: boolean;
  setOpenDialog: (openDialog: boolean) => void;
  onChange: (params: {
    chldrNm: string;
    chldrSeq: string;
    prntList: Array<{
      prntSeq: string;
    }>;
  }) => void;
}) {
  const { openDialog, setOpenDialog, onChange } = props;
  const {
    listData,
    schViewForm,
    handleChangeInput,
    handleChangePage,
    handleSearch,
    handleResetInput,
  } = useMemberList();

  const apiRef = useGridApiRef();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const columns: GridColDef[] = [
    {
      field: "chldrNm",
      headerName: "자녀이름",
      headerAlign: "center",
      width: 150,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "prntList",
      headerName: "보호자이름",
      headerAlign: "center",
      width: 150,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value
          .map((item: { prntSeq: number; prntNm: string }) => item.prntNm)
          .join(",")}`,
    },
    {
      field: "phNum",
      headerName: "휴대폰번호",
      headerAlign: "center",
      width: 200,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.prntList[0] && params.row.prntList[0].prntCph
          ? regPhoneNumber(params.row.prntList[0].prntCph)
          : "-",
    },
    {
      field: "chldrAddr",
      headerName: "주소",
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "a",
      headerName: "회원선택",
      width: 90,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <XTButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              onChange(params.row);
              handleCloseDialog();
            }}
          >
            선택
          </XTButton>
        );
      },
    },
  ];

  return (
    <XTDialog
      disablePortal
      className="lg"
      open={openDialog}
      css={globalCSS.detailContainer}
    >
      <XTDialogTitle className="flex-title">
        <div>
          <span>회원조회</span>
        </div>

        <button
          type="button"
          className="close-btn"
          onClick={() => {
            handleCloseDialog();
          }}
        >
          <span css={globalCSS.hidden}>닫기</span>
          <i css={globalCSS.icoClose} />
        </button>
      </XTDialogTitle>

      <XTDialogContent>
        <p className="noti">물품신청을 진행할 회원을 선택해주세요.</p>
        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 4, // 하단 마진 값 설정
          }}
        >
          <Grid item>
            <XTSelect
              sx={{
                minWidth: 120,
              }}
              name="schFld"
              onChange={handleChangeInput}
              value={schViewForm.schFld}
              MenuProps={{
                MenuListProps: { sx: { padding: 0 } },
              }}
            >
              <XTMenuItem value="chldr_nm">자녀이름</XTMenuItem>
              <XTMenuItem value="prnt_nm">보호자이름</XTMenuItem>
              <XTMenuItem value="prnt_cph">휴대폰번호</XTMenuItem>
            </XTSelect>
          </Grid>
          <Grid item>
            <XTInput
              sx={{
                minWidth: 280,
              }}
              name="schTxt"
              value={schViewForm.schTxt}
              fullWidth
              placeholder="검색어를 입력해 주세요."
              onChange={handleChangeInput}
            />
          </Grid>
          <Grid item>
            <XTButton
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                handleSearch();
              }}
            >
              조회
            </XTButton>
          </Grid>
          <Grid item>
            <XTButton
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                handleResetInput();
              }}
            >
              초기화
            </XTButton>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            marginBottom: 4, // 하단 마진 값 설정
          }}
        >
          <XTDataGrid
            className="clickable"
            columns={columns}
            rows={listData.innoBoostChildList.list}
            getRowId={(params) => params.index}
            apiRef={apiRef}
            experimentalFeatures={{
              ariaV7: true,
            }}
            slots={{
              noRowsOverlay: () => (
                <div className="no-data">검색 결과가 존재하지 않습니다.</div>
              ),
              noResultsOverlay: () => (
                <div className="no-data">검색 결과가 존재하지 않습니다.</div>
              ),
            }}
            rowHeight={38}
            columnHeaderHeight={38}
            hideFooter
            components={{
              LoadingOverlay: LinearProgress,
            }}
          />
        </Grid>
        <Grid container justifyContent="center">
          <Box>
            <Pagination
              page={
                listData.innoBoostChildList.page
                  ? listData.innoBoostChildList.page
                  : 0
              }
              count={
                listData.innoBoostChildList.totalPageSize
                  ? listData.innoBoostChildList.totalPageSize
                  : 0
              }
              onChange={handleChangePage}
            />
          </Box>
        </Grid>
      </XTDialogContent>
      <XTDialogActions></XTDialogActions>
    </XTDialog>
  );
}
